@import 'scss/shared';

.servicesInfo {
  display: block;
  margin: 2px 0 0 0;
  padding: 12px 8px 12px 16px;
  border: none;
  border-radius: 8px;
  background: $gray-light-3;
  text-align: left;

  &:hover {
    background: $blue-10;
  }

  .title {
    margin: 0;
    color: $gray-dark-7 !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  .description {
    margin: 0;
    color: $gray-dark-6 !important;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
}
