@import 'scss/shared';

.infoAlert {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 4px;
  margin: 0 0 16px 0;

  &.borderTopOnly {
    padding: 8px 12px;
  }

  .icon {
    width: 18px;
    height: auto;
  }

  .text {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.3px;

    :global {
      .underline {
        text-decoration: underline;
      }
    }
  }

  &.blue {
    background: $blue-10;
    border: 1px solid $bright-blue;

    &.borderTopOnly {
      border: initial;
      border-top: 2px solid $bright-blue;
    }

    .text {
      color: $gray-dark-7;
    }

    .icon {
      color: $bright-blue;
    }
  }

  &.yellow {
    background: #fffcf2;
    border: 1px solid $sunny-yellow;

    &.borderTopOnly {
      border: initial;
      border-top: 2px solid $sunny-yellow;
    }

    .text {
      color: $gray-dark-7;
    }

    .icon {
      color: $sunny-yellow;
    }
  }

  &.red {
    background: $orange-10;
    border: 1px solid $burn-orange;

    &.borderTopOnly {
      border: initial;
      border-top: 2px solid $burn-orange;
    }

    .text {
      color: $gray-dark-7;
    }

    .icon {
      color: $burn-orange;
    }
  }

  &.neutral {
    padding: 4px 16px;
    background: transparent;
    border: none;

    &.borderTopOnly {
      border: initial;
      border-top: none;
    }

    .text {
      color: $gray-dark-6;
    }

    .icon {
      color: $burn-orange;
    }
  }
}
