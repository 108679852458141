@import 'scss/shared';

.toggle {
  width: 100%;
  display: flex;

  .arrowWrapper {
    display: flex;
    align-items: center;
    width: 12px;
    right: 0;

    .arrowUp {
      transform: rotate(180deg);
      color: $gray-light-1;
    }

    .arrowDown {
      transform: rotate(0deg);
      color: $gray-light-1;
    }

    .arrowBlack {
      color: $gray-dark-8;
    }
  }
}

.toggleArrow {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  color: $gray-dark-5;
}

.open {
  background: $gray-light-3;
}
