@import 'scss/shared';

.label {
  display: block;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  margin: 12px 0 4px 0;
  color: $gray-dark-7;
}
