$grid-breakpoints: (
        xs: 0,
  // Smallest phones
        xsm: 375px,
  // For iPhone SE and larger
        sm: 576px,
  // for bigger mobile phones and smaller tables
        md: 768px,
  // For tables (vertical mode)
        lg: 992px,
  // For tables (horizontal mode) and smaller notebooks
        xl: 1200px,
  // For desktops
        xxl: 1366px,
  // Extra large, for sales page support
        xxxl: 1920px,
  // Extra extra large, for navbar large screens
) !default;

$container-max-widths: (
  //sm: 540px, // Removed by Lara
  //md: 720px, // Removed by Lara
        lg: 832px,
        xl: 1120px,
        xxl: 1200px
) !default;

$success: #42d6a8;
$danger: #f06666;
$sunset-red: #f7555e;

$black: #000000 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue;

$info-light: #9dc0e7;
$washed-blue: #858d9f;
$light-blue: #cfd6e4;
$bright-blue: #007bff;
$dark-blue: #5b6271;
$extra-dark-blue: #141415;
$deep-blue: #2a3d71;
$deeper-blue: #0061ff;
$chalk: #37383c;
$orange: #ff5722;
$darker-orange: #ff4016;
$red-orange: #ff1e05;
$darker-white: #f8fbff;

$baby-blue: #007bff;
$burn-orange: #ff5722;
$sunny-yellow: #ffc107;
$misty-green: #00d18a;
$sunset-red: #f7555e;

$blue-2: #cce5ff;
$blue-5: #007bff;
$blue-4: #66b0ff;
$blue-6: #0061ff;

$blue-200: #003bff;
$blue-170: #004eff;
$blue-140: #0061ff;
$blue-100: $blue-6;
$blue-60: #66b0ff;
$blue-40: #99caff;
$blue-20: #cce5ff;
$blue-10: #e6f2ff;

$orange-200: #ff1e05;
$orange-170: #ff2f0d;
$orange-140: #ff4016;
$orange-100: $burn-orange;
$orange-60: #ff9a7a;
$orange-40: #ffbca7;
$orange-20: #ffddd3;
$orange-10: #ffeee9;

// NEW grays
$white: #ffffff;
$gray-light-1: $white;
$gray-light-2: #f8fbff;
$gray-light-3: #f3f6fb;
$gray-light-4: #d8dde7;
$gray-light-45: #b6bfcd;
$gray-light-6: #cfd6e4;
$gray-dark-5: #858d9f;
$gray-dark-6: #5b6271;
$gray-dark-640: #5b627140;
$gray-dark-7: #37383c;
$gray-dark-8: #141415;

$gray-blue: #f3f6fbff;

// @TODO remove duplicate colors and clean naming
$blue-2: #cce5ff;
$blue-4: #66b0ff;
$blue-6: #0061ff;

$express-color: #ffc107;

$tertiary: $gray-light-3;

$container-size: 1300px;

$border-radius: 13px;
$grid-gutter-width: 30px !default;
$grid-gutter-mobile-width: 20px !default;
$dashboard-menu-width: 110px;

$max-width: 1160px;

$default-duration: 0.2s;

$default-box-shadow: 0px 2px 4px rgba(23, 70, 128, 0.2);
$middle-box-shadow: 0px 4px 10px rgba(23, 70, 128, 0.15);
$raised-box-shadow: 0px 4px 20px rgba(23, 70, 128, 0.1);

$border-radius-dropdown: 4px;
$border-radius: 8px;
$border-radius-large: 8px;

$box-shadow-header: 0px 2px 4px rgba(23, 70, 128, 0.07);
$box-shadow-selected: 0px 2px 4px rgba(23, 70, 128, 0.2);
$box-shadow-dropdown: 0px 4px 10px rgba(23, 70, 128, 0.15);
$box-shadow-active: 0px 4px 20px rgba(23, 70, 128, 0.1);

$input-transition: all 0.1s linear;

$input-height: 46px;
$form-disabled-opacity: 0.5;
