@import 'scss/shared';

.countriesWrapper {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 100%;
  background: $gray-light-1;
  border: 1px solid $white;
  border-radius: 7px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    padding: 0;
  }

  @include media-breakpoint-up(xl) {
    width: 66.66%;
    border-radius: 7px 0 0 7px;
  }

  &.countriesNotSelected {
    border-radius: 7px 7px 0 0;
  }

  &.isSimple {
    @include media-breakpoint-up(xl) {
      width: 100%;
    }
  }

  .country {
    padding: 4px;
    height: 72px;
    display: flex;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &.loading {
      opacity: 0.4;
    }

    .error {
      color: $danger;
      position: absolute;
      bottom: -7px;
      font-size: 12px;
      font-weight: 500;
      left: 10px;
    }

    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 7px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid transparent;
      background: transparent;
      transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
      position: relative;

      &:hover {
        background: $gray-light-3;
      }

      &:focus,
      &.focus {
        background: transparent;
        border: 1px solid $blue-40;
        z-index: 99999;
      }

      .label {
        cursor: pointer;
        margin: 0;
        color: $gray-dark-8;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.3px;
        padding: 8px 0 0 0;
        width: 100%;
        text-align: left;
        text-transform: none;
      }

      .nativeField {
        border: none;
        width: 100%;
        padding: 33px 7px 8px;
        margin: -8px -8px -21px;
        left: 0;
        z-index: 0;
        background: transparent;
        position: relative;
        top: -20px;

        &.hasError {
          color: $danger;
        }
      }

      .searchContainer {
        .searchInput {
          background: transparent;
          border: none;
          padding: 0 0 8px 0;
          min-height: 24px;
          position: relative;
        }

        .selected {
          color: $baby-blue;
          font-weight: 700;
        }

        // icon
        > :first-child > div {
          display: none;
        }

        .dropdown {
          top: calc(100% - 4px);
          left: -8px;
          width: calc(100% + 16px);

          @include media-breakpoint-up(xl) {
            top: calc(100% + 2px);
            left: -8px;
            width: 350px;
          }
        }
      }

      .gradient {
        position: absolute;
        width: 40px;
        height: 24px;
        background: linear-gradient(to left, $white 0%, $white 40%, transparent 100%);
        right: 5px;
        bottom: 6px;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        .gradient {
          opacity: 0;
        }
      }

      .placeholder {
        margin: 0;
        color: $gray-light-45;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.3px;
        padding: 0 0 8px 0;
        min-height: 32px;
        text-align: left;
      }

      .closeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0px;
        bottom: 6px;
        background: transparent;
        border: none;
        height: 24px;
        width: 24px;
        background: $gray-light-1;
        transition: background 0.2s ease-in-out;

        .icon {
          height: 16px;
          color: $gray-dark-5;
        }
      }

      &:hover {
        .closeBtn {
          background: $gray-light-3;

          &.hover {
            background: transparent;
          }
        }

        &.focus {
          .closeBtn {
            background: transparent;
          }
        }
      }

      &:focus {
        .closeBtn {
          background: transparent;
        }
      }
    }
  }

  .separator {
    display: block;
    height: 1px;
    background: $gray-light-4;
    width: calc(100% - 16px);
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 1px;
      height: 58px;
      margin: auto 0;
    }
  }
}
