@import 'scss/shared';

.container {
  position: fixed;
  z-index: 100;
  max-height: 80vh;
  padding: 0 8px;
  opacity: 0;
  margin-left: -10px;

  &.slide {
    animation: slide-down 0.1s;
  }

  &.shown {
    opacity: 1;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    background: white;
    margin-top: 8px;
    z-index: 99999;
    overflow-y: auto;
    box-shadow: $box-shadow-dropdown;
    border-radius: $border-radius-dropdown;

    &.top {
      margin-top: -8px !important;
    }

    .menu {
      display: flex;

      z-index: 99999;

      &.rendering {
        opacity: 0;
      }

      &.row {
        padding: 16px;
        flex-direction: row;
        width: 296px;
        flex-wrap: wrap;

        &2 {
          width: 283px;
          gap: 0 8px;

          & > a,
          & > div {
            flex: 0 0 calc(50% - 4px);
            padding: 8px;
            min-width: 0 !important;
          }
        }

        &4 {
          & > a,
          & > div {
            flex: 0 0 25%;
            padding: 8px;
            min-width: 0 !important;
          }
        }
      }

      &.column {
        flex-direction: column;

        & a[role='menuitem'],
        & div[role='none'] {
          min-width: 240px;
          width: auto;
        }
      }
    }
  }
}
