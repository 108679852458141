@import 'scss/shared';

.servicesSeparator {
  margin: 2px 0;

  .text {
    margin: 0;
    padding: 3px 12px;
    color: $gray-dark-7 !important;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.05px;

    &.green {
      background: #b6edb8;
    }

    &.blue {
      background: $blue-10;
    }
  }
}
