@import 'scss/shared';

.accountTypeWrapper {
  display: none;
  background: $gray-light-1;
  padding: 0 8px 0 8px;
  margin: -1px 0 0 0;

  @include media-breakpoint-up(xl) {
    display: flex;
    padding: 0;
    max-width: 302px;
    margin: 0;
    min-width: 248px;
  }

  &.mobile {
    display: flex;
    flex-direction: column;
  }

  .separator {
    display: block;
    background: $gray-light-4;
    width: 1px;
    height: calc(100% - 16px);
    margin: auto 0;
  }

  .horizontalSeparator {
    display: block;
    height: 1px;
    background: $gray-light-4;
    width: calc(100% - 16px);
    margin: 0 auto 4px auto;
  }

  .accountType {
    display: flex;
    flex-grow: 1;
    padding: 4px;
    min-width: 220px;

    &.loading {
      opacity: 0.5;
    }

    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 8px;
      width: 100%;

      .label {
        margin: 0;
        color: $gray-dark-8;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.3px;
        padding: 5px 0 0 0;

        @include media-breakpoint-up(xl) {
          padding: 0;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 0 8px 0;

        @include media-breakpoint-up(xl) {
          padding: 0;
        }

        .option {
          display: flex;
          align-items: center;
          gap: 4px;
          background: transparent;
          border: none;
          padding: 0;
          cursor: pointer;

          .textActive,
          .textDefault {
            margin: 0;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
          }

          .textActive {
            color: $bright-blue;
            font-weight: 700;
          }

          .textDefault {
            color: $gray-dark-7;
            font-weight: 400;
          }

          .iconActive,
          .iconDefault {
            width: 16px;
            height: auto;
          }

          .iconActive {
            color: $bright-blue;
          }

          .iconDefault {
            color: $gray-light-4;
          }
        }

        .tooltip {
          margin-left: -11px;

          svg {
            color: $gray-light-4;
          }
        }
      }
    }
  }
}
