@import 'scss/shared';

.button {
  cursor: pointer;
  display: flex;
  height: 64px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: $blue-140;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid $gray-light-1;
  position: relative;
  transition: background 0.2s ease-in-out;

  @include media-breakpoint-up(xl) {
    min-width: 200px;
    max-width: 240px;
    height: 74px;
    border-bottom-left-radius: initial;
    border-top-right-radius: 8px;
  }

  &:hover {
    background: $blue-170;
    text-decoration: none;
  }

  &.disabled {
    background: $blue-60;
    text-decoration: none;
  }

  .text {
    color: $gray-light-1;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin: initial !important;
  }

  .icon {
    color: $gray-light-1;
    width: 14px;
  }

  .loadingOverlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .loadingIcon {
      color: white;
      width: 20px;
      height: 20px;
      animation-name: spin;
      animation-iteration-count: infinite;
      animation-duration: 1000ms;
      animation-timing-function: linear;
    }
  }
}
