@import 'scss/shared';

.unloadedWrapper,
.wrapper {
  min-height: 219px;

  @include media-breakpoint-up(md) {
    min-height: 138px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 74px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: transparent;
  box-shadow: 0px 4px 20px 0px rgba(23, 70, 128, 0.1);

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  &.shadow {
    @include media-breakpoint-up(lg) {
      box-shadow: 0 0 80px rgba(0, 59, 255, 0.5);
    }
  }

  .flexibleWidth {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex: 1;
      flex-direction: row;
    }

    .transparentSeparator {
      display: block;
      height: 4px;
      background: transparent;
    }
  }
}
