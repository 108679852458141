@import 'scss/shared';

.container {
  position: relative;

  .button {
    display: flex;
    align-items: center;
    height: 24px;
    border: none;
    background: none;

    .icon {
      position: relative;
      z-index: 1;
      color: $blue-60;
      display: flex;
      padding: 8px 12px;
      transition: color $default-duration;

      &.isMuted {
        color: $gray-dark-5;
      }

      &:hover {
        color: $gray-dark-8;
      }
    }
  }

  .tooltip {
    white-space: pre-wrap;
    opacity: 0;
    width: 234px;
    color: $gray-dark-8;
    font-size: 14px;
    line-height: 22px;
    z-index: 2;
    position: absolute;
    margin-top: 15px;
    margin-left: -30px;
    top: -200%;
    left: 100%;
    pointer-events: none;
    border: 1px solid $gray-light-4;
    background: $gray-light-1;
    border-radius: 4px;
    box-shadow: $middle-box-shadow;
    padding: 12px 16px;
    font-weight: 400;
    text-align: left;

    &.isActive {
      pointer-events: all;
      z-index: 100;
      animation: fade-in-and-translateY $default-duration forwards;
      margin-left: -5px;
      top: 50%;

      @include media-breakpoint-up(md) {
        margin-left: -30px;
      }
    }

    @media (max-width: 960px) {
      position: absolute;
      top: 1px;
      left: -145px;
    }
  }
}
