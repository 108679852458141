@keyframes _653C7G_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _653C7G_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes _653C7G_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes _653C7G_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _653C7G_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes _653C7G_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

._653C7G_selectedWrapper {
  background-color: #fff;
  margin-top: -5px;
  padding: 0 16px 12px;
  font-weight: 600;
  line-height: 24px;
}

._653C7G_selectedWrapper ._653C7G_separator {
  background: #d8dde7;
  height: 1px;
  margin-bottom: 12px;
  display: block;
}

._653C7G_selectedWrapper ._653C7G_title {
  color: #141415;
  font-size: 16px;
}

._653C7G_selectedWrapper ._653C7G_iconServiceWrapper {
  color: #007bff;
  justify-content: space-between;
  display: flex;
}

._653C7G_selectedWrapper ._653C7G_iconServiceWrapper ._653C7G_icon {
  margin-right: 8px;
}

._653C7G_selectedWrapper ._653C7G_iconServiceWrapper ._653C7G_chevronIcon {
  color: #858d9f;
}

@keyframes a0-xKq_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes a0-xKq_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes a0-xKq_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes a0-xKq_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes a0-xKq_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes a0-xKq_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.a0-xKq_notificationTemplate {
  align-items: center;
  display: flex;
}

.a0-xKq_icon {
  flex: 0 0 30px;
  font-size: 20px;
}

@media (min-width: 768px) {
  .a0-xKq_icon {
    font-size: 28px;
  }
}

.a0-xKq_icon.a0-xKq_success {
  color: #28a745;
}

.a0-xKq_icon.a0-xKq_danger {
  color: #f7555e;
}

.a0-xKq_icon.a0-xKq_info {
  color: #66b0ff;
}

.a0-xKq_content {
  flex: 1 0;
  padding-left: 10px;
}

.a0-xKq_content ul {
  padding-inline-start: 15px;
}

@keyframes PIDyeW_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PIDyeW_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes PIDyeW_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes PIDyeW_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes PIDyeW_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes PIDyeW_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.PIDyeW_unloadedWrapper, .PIDyeW_wrapper {
  min-height: 219px;
}

@media (min-width: 768px) {
  .PIDyeW_unloadedWrapper, .PIDyeW_wrapper {
    min-height: 138px;
  }
}

@media (min-width: 1200px) {
  .PIDyeW_unloadedWrapper, .PIDyeW_wrapper {
    min-height: 74px;
  }
}

.PIDyeW_wrapper {
  background: none;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 20px #1746801a;
}

@media (min-width: 1200px) {
  .PIDyeW_wrapper {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .PIDyeW_wrapper.PIDyeW_shadow {
    box-shadow: 0 0 80px #003bff80;
  }
}

.PIDyeW_wrapper .PIDyeW_flexibleWidth {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1200px) {
  .PIDyeW_wrapper .PIDyeW_flexibleWidth {
    flex-direction: row;
    flex: 1;
  }
}

.PIDyeW_wrapper .PIDyeW_flexibleWidth .PIDyeW_transparentSeparator {
  background: none;
  height: 4px;
  display: block;
}

@keyframes ayiSDW_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ayiSDW_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ayiSDW_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes ayiSDW_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ayiSDW_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ayiSDW_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ayiSDW_container {
  position: relative;
}

.ayiSDW_container .ayiSDW_button {
  background: none;
  border: none;
  align-items: center;
  height: 24px;
  display: flex;
}

.ayiSDW_container .ayiSDW_button .ayiSDW_icon {
  z-index: 1;
  color: #66b0ff;
  padding: 8px 12px;
  transition: color .2s;
  display: flex;
  position: relative;
}

.ayiSDW_container .ayiSDW_button .ayiSDW_icon.ayiSDW_isMuted {
  color: #858d9f;
}

.ayiSDW_container .ayiSDW_button .ayiSDW_icon:hover {
  color: #141415;
}

.ayiSDW_container .ayiSDW_tooltip {
  white-space: pre-wrap;
  opacity: 0;
  color: #141415;
  z-index: 2;
  pointer-events: none;
  text-align: left;
  background: #fff;
  border: 1px solid #d8dde7;
  border-radius: 4px;
  width: 234px;
  margin-top: 15px;
  margin-left: -30px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  position: absolute;
  top: -200%;
  left: 100%;
  box-shadow: 0 4px 10px #17468026;
}

.ayiSDW_container .ayiSDW_tooltip.ayiSDW_isActive {
  pointer-events: all;
  z-index: 100;
  margin-left: -5px;
  animation: .2s forwards ayiSDW_fade-in-and-translateY;
  top: 50%;
}

@media (min-width: 768px) {
  .ayiSDW_container .ayiSDW_tooltip.ayiSDW_isActive {
    margin-left: -30px;
  }
}

@media (max-width: 960px) {
  .ayiSDW_container .ayiSDW_tooltip {
    position: absolute;
    top: 1px;
    left: -145px;
  }
}

@keyframes _843-iW_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _843-iW_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes _843-iW_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes _843-iW_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _843-iW_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes _843-iW_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

._843-iW_accountTypeWrapper {
  background: #fff;
  margin: -1px 0 0;
  padding: 0 8px;
  display: none;
}

@media (min-width: 1200px) {
  ._843-iW_accountTypeWrapper {
    min-width: 248px;
    max-width: 302px;
    margin: 0;
    padding: 0;
    display: flex;
  }
}

._843-iW_accountTypeWrapper._843-iW_mobile {
  flex-direction: column;
  display: flex;
}

._843-iW_accountTypeWrapper ._843-iW_separator {
  background: #d8dde7;
  width: 1px;
  height: calc(100% - 16px);
  margin: auto 0;
  display: block;
}

._843-iW_accountTypeWrapper ._843-iW_horizontalSeparator {
  background: #d8dde7;
  width: calc(100% - 16px);
  height: 1px;
  margin: 0 auto 4px;
  display: block;
}

._843-iW_accountTypeWrapper ._843-iW_accountType {
  flex-grow: 1;
  min-width: 220px;
  padding: 4px;
  display: flex;
}

._843-iW_accountTypeWrapper ._843-iW_accountType._843-iW_loading {
  opacity: .5;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 8px;
  display: flex;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_label {
  color: #141415;
  letter-spacing: -.3px;
  margin: 0;
  padding: 5px 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

@media (min-width: 1200px) {
  ._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_label {
    padding: 0;
  }
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom {
  align-items: center;
  gap: 8px;
  padding: 0 0 8px;
  display: flex;
}

@media (min-width: 1200px) {
  ._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom {
    padding: 0;
  }
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  gap: 4px;
  padding: 0;
  display: flex;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_textActive, ._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_textDefault {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_textActive {
  color: #007bff;
  font-weight: 700;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_textDefault {
  color: #37383c;
  font-weight: 400;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_iconActive, ._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_iconDefault {
  width: 16px;
  height: auto;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_iconActive {
  color: #007bff;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_option ._843-iW_iconDefault {
  color: #d8dde7;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_tooltip {
  margin-left: -11px;
}

._843-iW_accountTypeWrapper ._843-iW_accountType ._843-iW_input ._843-iW_bottom ._843-iW_tooltip svg {
  color: #d8dde7;
}

@keyframes Y8UIVq_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Y8UIVq_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes Y8UIVq_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes Y8UIVq_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes Y8UIVq_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes Y8UIVq_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Y8UIVq_button {
  cursor: pointer;
  background: #0061ff;
  border: 1px solid #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  height: 64px;
  padding: 8px 24px;
  transition: background .2s ease-in-out;
  display: flex;
  position: relative;
}

@media (min-width: 1200px) {
  .Y8UIVq_button {
    border-bottom-left-radius: initial;
    border-top-right-radius: 8px;
    min-width: 200px;
    max-width: 240px;
    height: 74px;
  }
}

.Y8UIVq_button:hover {
  background: #004eff;
  text-decoration: none;
}

.Y8UIVq_button.Y8UIVq_disabled {
  background: #66b0ff;
  text-decoration: none;
}

.Y8UIVq_button .Y8UIVq_text {
  color: #fff;
  text-align: center;
  letter-spacing: -.3px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: initial !important;
}

.Y8UIVq_button .Y8UIVq_icon {
  color: #fff;
  width: 14px;
}

.Y8UIVq_button .Y8UIVq_loadingOverlay {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.Y8UIVq_button .Y8UIVq_loadingOverlay .Y8UIVq_loadingIcon {
  color: #fff;
  width: 20px;
  height: 20px;
  animation-name: Y8UIVq_spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes lj7dRq_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lj7dRq_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes lj7dRq_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes lj7dRq_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lj7dRq_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes lj7dRq_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.lj7dRq_label {
  color: #37383c;
  margin: 12px 0 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

@keyframes _8QUMva_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8QUMva_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes _8QUMva_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes _8QUMva_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes _8QUMva_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes _8QUMva_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

._8QUMva_countriesWrapper {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 7px;
  flex-direction: column;
  width: 100%;
  padding: 4px;
  display: flex;
}

@media (min-width: 768px) {
  ._8QUMva_countriesWrapper {
    flex-direction: row;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  ._8QUMva_countriesWrapper {
    border-radius: 7px 0 0 7px;
    width: 66.66%;
  }
}

._8QUMva_countriesWrapper._8QUMva_countriesNotSelected {
  border-radius: 7px 7px 0 0;
}

@media (min-width: 1200px) {
  ._8QUMva_countriesWrapper._8QUMva_isSimple {
    width: 100%;
  }
}

._8QUMva_countriesWrapper ._8QUMva_country {
  height: 72px;
  padding: 4px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  ._8QUMva_countriesWrapper ._8QUMva_country {
    width: 50%;
  }
}

._8QUMva_countriesWrapper ._8QUMva_country._8QUMva_loading {
  opacity: .4;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_error {
  color: #f06666;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -7px;
  left: 10px;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input {
  background: none;
  border: 1px solid #0000;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 7px;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  display: flex;
  position: relative;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:hover {
  background: #f3f6fb;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:focus, ._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input._8QUMva_focus {
  z-index: 99999;
  background: none;
  border: 1px solid #99caff;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_label {
  cursor: pointer;
  color: #141415;
  letter-spacing: -.3px;
  text-align: left;
  text-transform: none;
  width: 100%;
  margin: 0;
  padding: 8px 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_nativeField {
  z-index: 0;
  background: none;
  border: none;
  width: 100%;
  margin: -8px -8px -21px;
  padding: 33px 7px 8px;
  position: relative;
  top: -20px;
  left: 0;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_nativeField._8QUMva_hasError {
  color: #f06666;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_searchContainer ._8QUMva_searchInput {
  background: none;
  border: none;
  min-height: 24px;
  padding: 0 0 8px;
  position: relative;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_searchContainer ._8QUMva_selected {
  color: #007bff;
  font-weight: 700;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_searchContainer > :first-child > div {
  display: none;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_searchContainer ._8QUMva_dropdown {
  width: calc(100% + 16px);
  top: calc(100% - 4px);
  left: -8px;
}

@media (min-width: 1200px) {
  ._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_searchContainer ._8QUMva_dropdown {
    width: 350px;
    top: calc(100% + 2px);
    left: -8px;
  }
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_gradient {
  opacity: 1;
  background: linear-gradient(to left, #fff 0% 40%, #0000 100%);
  width: 40px;
  height: 24px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  bottom: 6px;
  right: 5px;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:hover ._8QUMva_gradient {
  opacity: 0;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_placeholder {
  color: #b6bfcd;
  letter-spacing: -.3px;
  text-align: left;
  min-height: 32px;
  margin: 0;
  padding: 0 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_closeBtn {
  cursor: pointer;
  background: #fff;
  border: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: background .2s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 6px;
  right: 0;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input ._8QUMva_closeBtn ._8QUMva_icon {
  color: #858d9f;
  height: 16px;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:hover ._8QUMva_closeBtn {
  background: #f3f6fb;
}

._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:hover ._8QUMva_closeBtn._8QUMva_hover, ._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:hover._8QUMva_focus ._8QUMva_closeBtn, ._8QUMva_countriesWrapper ._8QUMva_country ._8QUMva_input:focus ._8QUMva_closeBtn {
  background: none;
}

._8QUMva_countriesWrapper ._8QUMva_separator {
  background: #d8dde7;
  width: calc(100% - 16px);
  height: 1px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  ._8QUMva_countriesWrapper ._8QUMva_separator {
    width: 1px;
    height: 58px;
    margin: auto 0;
  }
}

@keyframes ubvVfa_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ubvVfa_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ubvVfa_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes ubvVfa_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ubvVfa_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ubvVfa_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ubvVfa_overlay {
  background: #0000001a;
  width: 100%;
  height: 100%;
  position: fixed;
}

.ubvVfa_dropdown {
  padding: 0;
  display: inline-block;
  position: relative;
}

@media screen and (min-width: 400px) {
  .ubvVfa_dropdown {
    width: inherit;
    position: relative;
  }
}

@keyframes PP0WPa_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PP0WPa_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes PP0WPa_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes PP0WPa_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes PP0WPa_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes PP0WPa_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.PP0WPa_subMenu > .PP0WPa_item {
  border-radius: 0 !important;
}

.PP0WPa_item {
  color: #000;
  background: #fff;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
}

.PP0WPa_item.PP0WPa_withSubmenu {
  background: none;
  padding: 0;
}

.PP0WPa_item:hover {
  color: #000;
  cursor: pointer;
  outline-offset: -1px;
  background: #f3f6fb;
  text-decoration: none;
}

.PP0WPa_item.PP0WPa_selected {
  color: #fff;
  background: #007bff;
  border-radius: 2px;
}

.PP0WPa_item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.PP0WPa_item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.PP0WPa_subMenu {
  flex-direction: row;
  width: 100%;
  display: flex;
}

@keyframes vb8gRG_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vb8gRG_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes vb8gRG_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes vb8gRG_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes vb8gRG_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes vb8gRG_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.vb8gRG_container {
  z-index: 100;
  opacity: 0;
  max-height: 80vh;
  margin-left: -10px;
  padding: 0 8px;
  position: fixed;
}

.vb8gRG_container.vb8gRG_slide {
  animation: .1s vb8gRG_slide-down;
}

.vb8gRG_container.vb8gRG_shown {
  opacity: 1;
}

.vb8gRG_container .vb8gRG_wrapper {
  z-index: 99999;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  margin-top: 8px;
  overflow-y: auto;
  box-shadow: 0 4px 10px #17468026;
}

.vb8gRG_container .vb8gRG_wrapper.vb8gRG_top {
  margin-top: -8px !important;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu {
  z-index: 99999;
  display: flex;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_rendering {
  opacity: 0;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row {
  flex-flow: wrap;
  width: 296px;
  padding: 16px;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row2 {
  gap: 0 8px;
  width: 283px;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row2 > a, .vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row2 > div {
  flex: 0 0 calc(50% - 4px);
  padding: 8px;
  min-width: 0 !important;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row4 > a, .vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_row4 > div {
  flex: 0 0 25%;
  padding: 8px;
  min-width: 0 !important;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_column {
  flex-direction: column;
}

.vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_column a[role="menuitem"], .vb8gRG_container .vb8gRG_wrapper .vb8gRG_menu.vb8gRG_column div[role="none"] {
  width: auto;
  min-width: 240px;
}

@keyframes YHcIga_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes YHcIga_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes YHcIga_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes YHcIga_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes YHcIga_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes YHcIga_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.YHcIga_toggle {
  width: 100%;
  display: flex;
}

.YHcIga_toggle .YHcIga_arrowWrapper {
  align-items: center;
  width: 12px;
  display: flex;
  right: 0;
}

.YHcIga_toggle .YHcIga_arrowWrapper .YHcIga_arrowUp {
  color: #fff;
  transform: rotate(180deg);
}

.YHcIga_toggle .YHcIga_arrowWrapper .YHcIga_arrowDown {
  color: #fff;
  transform: rotate(0);
}

.YHcIga_toggle .YHcIga_arrowWrapper .YHcIga_arrowBlack {
  color: #141415;
}

.YHcIga_toggleArrow {
  color: #858d9f;
  width: 6px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.YHcIga_open {
  background: #f3f6fb;
}

@keyframes spin_9c0b16 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-down_9c0b16 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-with-blur_9c0b16 {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes fade-in_9c0b16 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out_9c0b16 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in-and-translateY_9c0b16 {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.infoAlert_9c0b16 {
  border-radius: 4px;
  align-items: center;
  gap: 12px;
  margin: 0 0 16px;
  padding: 12px 16px;
  display: flex;
}

.infoAlert_9c0b16.borderTopOnly_9c0b16 {
  padding: 8px 12px;
}

.infoAlert_9c0b16 .icon_9c0b16 {
  width: 18px;
  height: auto;
}

.infoAlert_9c0b16 .text_9c0b16 {
  letter-spacing: -.3px;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.infoAlert_9c0b16 .text_9c0b16 .underline {
  text-decoration: underline;
}

.infoAlert_9c0b16.blue_9c0b16 {
  background: #e6f2ff;
  border: 1px solid #007bff;
}

.infoAlert_9c0b16.blue_9c0b16.borderTopOnly_9c0b16 {
  border: initial;
  border-top: 2px solid #007bff;
}

.infoAlert_9c0b16.blue_9c0b16 .text_9c0b16 {
  color: #37383c;
}

.infoAlert_9c0b16.blue_9c0b16 .icon_9c0b16 {
  color: #007bff;
}

.infoAlert_9c0b16.yellow_9c0b16 {
  background: #fffcf2;
  border: 1px solid #ffc107;
}

.infoAlert_9c0b16.yellow_9c0b16.borderTopOnly_9c0b16 {
  border: initial;
  border-top: 2px solid #ffc107;
}

.infoAlert_9c0b16.yellow_9c0b16 .text_9c0b16 {
  color: #37383c;
}

.infoAlert_9c0b16.yellow_9c0b16 .icon_9c0b16 {
  color: #ffc107;
}

.infoAlert_9c0b16.red_9c0b16 {
  background: #ffeee9;
  border: 1px solid #ff5722;
}

.infoAlert_9c0b16.red_9c0b16.borderTopOnly_9c0b16 {
  border: initial;
  border-top: 2px solid #ff5722;
}

.infoAlert_9c0b16.red_9c0b16 .text_9c0b16 {
  color: #37383c;
}

.infoAlert_9c0b16.red_9c0b16 .icon_9c0b16 {
  color: #ff5722;
}

.infoAlert_9c0b16.neutral_9c0b16 {
  background: none;
  border: none;
  padding: 4px 16px;
}

.infoAlert_9c0b16.neutral_9c0b16.borderTopOnly_9c0b16 {
  border: initial;
  border-top: none;
}

.infoAlert_9c0b16.neutral_9c0b16 .text_9c0b16 {
  color: #5b6271;
}

.infoAlert_9c0b16.neutral_9c0b16 .icon_9c0b16 {
  color: #ff5722;
}

@keyframes btzQVG_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes btzQVG_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes btzQVG_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes btzQVG_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes btzQVG_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes btzQVG_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btzQVG_servicesInfo {
  text-align: left;
  background: #f3f6fb;
  border: none;
  border-radius: 8px;
  margin: 2px 0 0;
  padding: 12px 8px 12px 16px;
  display: block;
}

.btzQVG_servicesInfo:hover {
  background: #e6f2ff;
}

.btzQVG_servicesInfo .btzQVG_title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #37383c !important;
}

.btzQVG_servicesInfo .btzQVG_description {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #5b6271 !important;
}

@keyframes CEUiNG_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes CEUiNG_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes CEUiNG_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes CEUiNG_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes CEUiNG_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes CEUiNG_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.CEUiNG_servicesSeparator {
  margin: 2px 0;
}

.CEUiNG_servicesSeparator .CEUiNG_text {
  letter-spacing: .05px;
  border-radius: 4px;
  margin: 0;
  padding: 3px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #37383c !important;
}

.CEUiNG_servicesSeparator .CEUiNG_text.CEUiNG_green {
  background: #b6edb8;
}

.CEUiNG_servicesSeparator .CEUiNG_text.CEUiNG_blue {
  background: #e6f2ff;
}

@keyframes UaTFEW_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes UaTFEW_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes UaTFEW_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes UaTFEW_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes UaTFEW_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes UaTFEW_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.UaTFEW_serviceBtn {
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 11px 8px 11px 16px;
  display: flex;
}

.UaTFEW_serviceBtn:hover {
  background: #f3f6fb;
}

.UaTFEW_serviceBtn.UaTFEW_selected {
  background: #e6f2ff;
  border: 1px solid #007bff;
}

.UaTFEW_serviceBtn.UaTFEW_selected .UaTFEW_icon {
  color: #007bff;
}

.UaTFEW_serviceBtn .UaTFEW_icon {
  color: #141415;
  width: 20px;
  height: 16px;
  transition: color .2s ease-in-out;
}

.UaTFEW_serviceBtn .UaTFEW_titleWrapper {
  gap: 6px;
  display: flex;
}

.UaTFEW_serviceBtn .UaTFEW_titleWrapper .UaTFEW_servicesTitle {
  letter-spacing: -.3px;
  text-align: left;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #141415 !important;
}

.UaTFEW_serviceBtn .UaTFEW_description {
  text-align: left;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #5b6271 !important;
}

@keyframes HSvula_spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HSvula_slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes HSvula_fade-in-with-blur {
  from {
    opacity: 0;
    filter: blur(18px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes HSvula_fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes HSvula_fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes HSvula_fade-in-and-translateY {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.HSvula_serviceWrapper {
  background: #fff;
  border-radius: 7px 7px 0 0;
  display: none;
}

.HSvula_serviceWrapper.HSvula_mobileWithoutServices {
  border-radius: 8px;
}

.HSvula_serviceWrapper.HSvula_loading {
  opacity: .5;
}

@media (min-width: 1200px) {
  .HSvula_serviceWrapper {
    border-radius: 0;
    flex: 1;
    width: 33.33%;
    padding: 0;
    display: flex;
  }
}

.HSvula_serviceWrapper.HSvula_show {
  display: flex;
}

.HSvula_serviceWrapper .HSvula_separator {
  background: #d8dde7;
  width: 1px;
  height: calc(100% - 16px);
  margin: auto 0;
  display: block;
}

.HSvula_serviceWrapper .HSvula_mobile {
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_mobile .HSvula_wrapper {
  padding: 8px;
}

.HSvula_serviceWrapper .HSvula_desktop {
  flex-grow: 1;
  padding: 4px;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input {
  cursor: pointer;
  background: initial;
  border: 1px solid #0000;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 7px;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input:hover {
  background: #f3f6fb;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input:focus, .HSvula_serviceWrapper .HSvula_desktop .HSvula_input.HSvula_focus {
  border: 1px solid #99caff;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_label {
  color: #141415;
  letter-spacing: -.3px;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 7px 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 0 0 7px;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom .HSvula_placeholder {
  color: #b6bfcd;
  letter-spacing: -.3px;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom .HSvula_icon {
  color: #858d9f;
  width: 16px;
  height: auto;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom .HSvula_selectedService {
  align-items: center;
  gap: 8px;
  min-width: 0;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom .HSvula_selectedService .HSvula_iconInput {
  color: #007bff;
  font-size: 16px;
}

.HSvula_serviceWrapper .HSvula_desktop .HSvula_input .HSvula_bottom .HSvula_selectedService .HSvula_text {
  color: #007bff;
  letter-spacing: -.3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 24px;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper {
  border-radius: 8px;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper .HSvula_dropdownContainer {
  background: #fff;
  border: 1px solid #d8dde7;
  border-radius: 8px;
  flex-direction: column;
  width: 350px;
  padding: 8px;
  display: flex;
  box-shadow: 0 4px 10px #17468026;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper .HSvula_dropdownContainer .HSvula_service {
  background: none;
  border: none;
  padding: 0;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper .HSvula_dropdownContainer .HSvula_noCountrySelected {
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  display: flex;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper .HSvula_dropdownContainer .HSvula_noCountrySelected .HSvula_icon {
  color: #141415;
  font-size: 14px;
}

.HSvula_serviceWrapper .HSvula_dropdownWrapper .HSvula_dropdownContainer .HSvula_noCountrySelected .HSvula_text {
  color: #37383c;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

/*# sourceMappingURL=index.css.map */
