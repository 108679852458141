@import "scss/shared";

.selectedWrapper {
  background-color: $white;
  padding: 0 16px 12px 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: -5px;

  .separator {
    display: block;
    height: 1px;
    background: $gray-light-4;
    margin-bottom: 12px;
  }

  .title {
    color: $gray-dark-8;
    font-size: 16px;
  }

  .iconServiceWrapper {
    display: flex;
    justify-content: space-between;
    color: $blue;

    .icon {
      margin-right: 8px;
    }

    .chevronIcon {
      color: $gray-dark-5;
    }
  }
}