@import 'scss/shared';

.serviceBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 11px 8px 11px 16px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  width: 100%;

  &:hover {
    background: $gray-light-3;
  }

  &.selected {
    border: 1px solid $bright-blue;
    background: $blue-10;

    .icon {
      color: $bright-blue;
    }
  }

  .icon {
    width: 20px;
    height: 16px;
    color: $gray-dark-8;
    transition: color 0.2s ease-in-out;
  }

  .titleWrapper {
    display: flex;
    gap: 6px;

    .servicesTitle {
      margin: 0;
      color: $gray-dark-8 !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.3px;
      text-align: left;
    }
  }

  .description {
    margin: 0;
    color: $gray-dark-6 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
}
