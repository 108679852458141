@import 'scss/shared';

.notificationTemplate {
  display: flex;
  align-items: center;
}

.icon {
  flex: 0 0 30px;
  font-size: 20px;
  @include media-breakpoint-up(md) {
    font-size: 28px;
  }


  &.success {
    color: $green;
  }

  &.danger {
    color: $sunset-red;
  }

  &.info {
    color: $blue-4;
  }
}

.content {
  padding-left: 10px;
  flex: 1 0;

  ul {
    padding-inline-start: 15px;
  }
}
