@import 'scss/shared';

.serviceWrapper {
  display: none;
  background: $gray-light-1;
  border-radius: 7px 7px 0 0;

  &.mobileWithoutServices {
    border-radius: 8px;
  }

  &.loading {
    opacity: 0.5;
  }

  @include media-breakpoint-up(xl) {
    display: flex;
    padding: 0;
    flex: 1;
    width: 33.33%;
    border-radius: 0;
  }

  &.show {
    display: flex;
  }

  .separator {
    display: block;
    background: $gray-light-4;
    width: 1px;
    height: calc(100% - 16px);
    margin: auto 0;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;

    .wrapper {
      padding: 8px;
    }
  }

  .desktop {
    display: flex;
    flex-grow: 1;
    padding: 4px;

    .input {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 7px;
      width: 100%;
      background: initial;
      border: 1px solid transparent;
      border-radius: 8px;
      transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

      &:hover {
        background: $gray-light-3;
      }

      &:focus,
      &.focus {
        border: 1px solid $blue-40;
      }

      .label {
        margin: 0;
        padding: 7px 0 0 0;
        width: 100%;
        color: $gray-dark-8;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.3px;
        text-align: left;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding: 0 0 7px 0;
        width: 100%;
        text-align: left;

        .placeholder {
          margin: 0;
          color: $gray-light-45;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.3px;
        }

        .icon {
          width: 16px;
          height: auto;
          color: $gray-dark-5;
        }

        .selectedService {
          display: flex;
          align-items: center;
          gap: 8px;
          min-width: 0; // Do not remove this, as it is needed so the text can be truncated (in .text class)

          .iconInput {
            font-size: 16px;
            color: $bright-blue;
          }

          .text {
            margin: 0;
            color: $bright-blue;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.3px;
            max-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .dropdownWrapper {
    border-radius: 8px;

    .dropdownContainer {
      padding: 8px;
      width: 350px;
      border-radius: 8px;
      border: 1px solid $gray-light-4;
      background: $gray-light-1;
      box-shadow: 0px 4px 10px 0px rgba(23, 70, 128, 0.15);
      display: flex;
      flex-direction: column;

      .service {
        padding: 0;
        border: none;
        background: transparent;
      }

      .noCountrySelected {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        .icon {
          font-size: 14px;
          color: $gray-dark-8;
        }

        .text {
          margin: 0;
          color: $gray-dark-7;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
}
